import { useState, useEffect } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../utils/init-firebase"; 

export const useFetchData = ({ cols, docId, username }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let idToFetch = docId;

        // If no docId but username is provided, fetch userId from the usernames collection
        if (!docId && username) {
          const usernameRef = doc(db, "usernames", username);
          const usernameSnapshot = await getDoc(usernameRef);

          if (usernameSnapshot.exists()) {
            idToFetch = usernameSnapshot.data().userId;
          } else {
            setError("Username not found");
            setLoading(false);
            return;
          }
        }

        // If docId or userId exists, fetch data from the main collection
        if (idToFetch) {
          const docRef = doc(db, cols, idToFetch);
          const docSnapshot = await getDoc(docRef);

          if (docSnapshot.exists()) {
            setData(docSnapshot.data());
          } else {
            setNotFound(true)
            setError("Document not found");
          }
        } else {
          setError("No document ID or username provided");
        }
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [cols, docId, username]);

  return { data, loading, setData, error, notFound };
};

import React from "react";
import { SortIcon } from "../../data/Data";

const ShowOrder = () => {
  return (
    <div className="m-5 py-3">
      <div className="flex p-2 text-sm  hover">
        <SortIcon />
        <div>Ordenar</div>
      </div>
      <div className="border-top-brown border-bottom-brown py-5  grid grid-cols-2 gap-x-2">
        <div>Destaque</div>
        <div>Novidade</div>
        <div>Avaliações</div>
        <div>Proximidade</div>
        <div>Valor</div>
        <div>Online</div>
        <div>Mídia de Comparação</div>
      </div>
    </div>
  );
};

export default ShowOrder;

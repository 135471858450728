import React, { useEffect, useState } from "react";
import To14 from "../../cards/To14";
import CheckText from "../../cards/CheckText";
import To15 from "../../cards/To15";
import To8s from "../../cards/To8s";
import To8a from "../../cards/To8a";
import To8 from "../../cards/To8";
import { UploadIcon } from "../../../data/Data";
import To13 from "../../cards/To13";
import To21 from "../../cards/To21";
import To25 from "../../cards/To25";
import To26 from "../../cards/To26";
import {
  activities,
  amenities,
  booleanOptions,
  characters,
  locations,
  paymentMethods,
  serviceOptions,
  sexOptions,
  timeRegionsOptions,
  valorsOptions,
} from "../../../data/Input";
import {
  GetCountries,
  GetState,
  GetCity,
} from "../../../../../server/functions/node_modules/react-country-state-city/dist/cjs";
import To30 from "../../cards/To30";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../utils/init-firebase";
import { useAuth } from "../../../contexts/AuthContext";
import { toLocal, toSlug } from "../../../controller/Functions";
import To36 from "../../cards/To36";

const Step3a = ({
  obj,
  dropdowns,
  handleSelect,
  toggleDropdown,
  handleChange,
  escortData,
  publishNow,
  saveDraft,
  cancelNow,
  handleCheck,
  values,
  setvalues,
  exp,
  setexp,
  who,
  contents,
  setcontents,
  loading,
}) => {
  const { currentUser } = useAuth();

  const [filebg, setFilebg] = useState("");
  const [filepfp, setFilepfp] = useState("");

  const [foto1, setFoto1] = useState(null);
  const [foto2, setFoto2] = useState(null);
  const [foto, setFoto] = useState(null);

  const [prevImgs, setprevImgs] = useState([]);
  const [prevVids, setprevVids] = useState([]);

  const [uploading, setUploading] = useState(false);

  const handleFileChange1 = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFilebg(URL.createObjectURL(selectedFile));
      setUploading(true);
      const storageImgRef = ref(
        storage,
        `users/${currentUser?.uid}/${selectedFile.name}`
      );

      // Upload the file to Firebase Storage
      const uploadTask = uploadBytesResumable(storageImgRef, selectedFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Optional: You can track upload progress here if needed
        },
        (error) => {
          console.error("Upload error:", error);
        },
        async () => {
          // Get the download URL once the upload is complete
          const imgUrl = await getDownloadURL(uploadTask.snapshot.ref);
          setFoto1(imgUrl); // Set the value to the URL
          setUploading(false); // Reset uploading state
        }
      );
    }
  };

  const handleFileChange2 = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFilepfp(URL.createObjectURL(selectedFile));
      setUploading(true);
      const storageImgRef = ref(
        storage,
        `users/${currentUser?.uid}/${selectedFile.name}`
      );

      // Upload the file to Firebase Storage
      const uploadTask = uploadBytesResumable(storageImgRef, selectedFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Optional: You can track upload progress here if needed
        },
        (error) => {
          console.error("Upload error:", error);
        },
        async () => {
          // Get the download URL once the upload is complete
          const imgUrl = await getDownloadURL(uploadTask.snapshot.ref);
          setFoto2(imgUrl); // Set the value to the URL
          setUploading(false); // Reset uploading state
        }
      );
    }
  };

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [citys, setCitys] = useState([]);
  const [citycode, setCitycode] = useState("");
  const [countryid, setCountryid] = useState([]);
  const [stateid, setStateid] = useState([]);
  const [countryisd, setCountryids] = useState(0);

  useEffect(() => {
    GetCountries().then((result) => {
      const formattedCountries = result.map((country) => ({
        name: country.name,
        value: country.name.toLowerCase(),
        info: country.iso3,
      }));
      setCountries(formattedCountries);
      setCountryid(result);
    });
  }, []);

  useEffect(() => {
    const selectedCountry = countryid?.find(
      (country) => country.name.toLowerCase() === escortData.lcountry
    );
    if (selectedCountry) {
      GetState(selectedCountry.id).then((result) => {
        setCountryids(selectedCountry.id);
        const formattedStateList = result.map((state) => ({
          name: state.name,
          value: state.name.toLowerCase(),
          info: state.iso3,
        }));
        setStates(formattedStateList);
        setStateid(result);
      });
    }
    /* eslint-disable-next-line */
  }, [escortData.lcountry]);

  useEffect(() => {
    const selectedState = stateid?.find(
      (state) => state.name.toLowerCase() === escortData.lstate
    );

    if (selectedState) {
      GetCity(countryisd, selectedState.id).then((result) => {
        const formattedCityList = result.map((state) => ({
          name: state.name,
          value: state.name.toLowerCase(),
          info: state.iso3,
        }));
        setCitycode(selectedState.state_code);
        setCitys(formattedCityList);
      });
    }
    /* eslint-disable-next-line */
  }, [escortData.lstate]);

  useEffect(() => {
    if (escortData.lcity) {
      const lstater = `${toSlug(escortData.lcity)}-${citycode.toLowerCase()}`;
      const e = {
        target: {
          name: "lstater",
          value: toLocal(lstater),
        },
      };
      console.log(e);
      handleChange(e);
    }
    /* eslint-disable-next-line */
  }, [escortData.lcity]);

  useEffect(() => {
    const e = {
      target: {
        name: "imgCover",
        value: foto1,
      },
    };
    handleChange(e);
    /* eslint-disable-next-line */
  }, [foto1]);

  useEffect(() => {
    const e = {
      target: {
        name: "imgPfp",
        value: foto2,
      },
    };
    handleChange(e);
    /* eslint-disable-next-line */
  }, [foto2]);

  useEffect(() => {
    if (foto) {
      const e = {
        target: {
          name: "imgAds",
          value: foto,
        },
      };
      handleChange(e);
    }
    /* eslint-disable-next-line */
  }, [foto]);

  useEffect(() => {
    if (prevImgs) {
      const e = {
        target: {
          name: "imgs",
          value: prevImgs,
        },
      };
      handleChange(e);
    }
    /* eslint-disable-next-line */
  }, [prevImgs]);

  useEffect(() => {
    if (prevVids) {
      const e = {
        target: {
          name: "imgsVid",
          value: prevVids,
        },
      };
      handleChange(e);
    }
    /* eslint-disable-next-line */
  }, [prevVids]);

  return (
    <>
      <div className="my-5 sm:mx-5">
        {obj !== "content" && (
          <>
            <To15
              title={"Dados do anúncio"}
              subtitle={"Preencha os dados que serão exibidos no seu perfil."}
            />
            <div className="theme text-xs text-center">
              <label id="bg">
                <div className="btnbtn z-10 flex justify-center items-center bglb h-24 cursor-pointer  w-full rounded-lg">
                  <input
                    type={"file"}
                    accept="image/*"
                    onChange={handleFileChange1}
                    className="hidden"
                    id={"bg"}
                  />
                  {!filebg && (
                    <div className="flex justify-center items-center flex-col">
                      <UploadIcon size={20} />
                      <div className="">Insira uma foto de capa</div>
                    </div>
                  )}
                  {filebg && (
                    <img
                      src={filebg}
                      alt="bg"
                      className="w-full h-full object-cover rounded-lg z-10"
                    />
                  )}
                </div>
              </label>
              <label id="pfp">
                <div className="flex z-20 justify-center items-center -m-5 sm:-mt-10 ml-2 sm:ml-5 lg:ml-5 cursor-pointer btnbtn bglb w-20 h-20 p-2 text-8 rounded-full">
                  <input
                    type={"file"}
                    accept="image/*"
                    onChange={handleFileChange2}
                    className="hidden"
                    id={"pfp"}
                  />
                  {!filepfp && (
                    <div className="flex justify-center items-center flex-col">
                      <UploadIcon />
                      <div>Insira uma foto de perfil</div>
                    </div>
                  )}
                  {filepfp && (
                    <img
                      src={filepfp}
                      alt="pfp"
                      className="w-full h-full object-cover rounded-full z-20"
                    />
                  )}
                </div>
              </label>
              {uploading && <div>uploading...</div>}
            </div>
            <div className="mt-10">
              <div className="md:grid md:grid-cols-2 md:gap-x-3 items-center">
                <To8
                  placehoder={"Nome completo*"}
                  value={escortData.afullname}
                  onChange={(e) => handleChange(e)}
                  type={"text"}
                  name={"afullname"}
                />
                <To8s
                  placehoder={"Sexo*"}
                  value={escortData.asex}
                  dropdowns={dropdowns}
                  dropdownId={1}
                  toggleDropdown={toggleDropdown}
                  options={sexOptions}
                  handleSelect={handleSelect}
                  name={"asex"}
                />
                <To8
                  placehoder={"Data de nascimento*"}
                  value={escortData.adob}
                  onChange={(e) => handleChange(e)}
                  type={"date"}
                  name={"adob"}
                />
                <To8s
                  placehoder={"Atendimento*"}
                  value={escortData.aservice}
                  dropdowns={dropdowns}
                  dropdownId={2}
                  toggleDropdown={toggleDropdown}
                  options={serviceOptions}
                  handleSelect={handleSelect}
                  name={"aservice"}
                />
                <To8
                  placehoder={"Telefone*"}
                  value={escortData.atelephone}
                  onChange={(e) => handleChange(e)}
                  type={"number"}
                  name={"atelephone"}
                />
                <To8
                  placehoder={"Whatsapp"}
                  value={escortData.awhatsapp}
                  onChange={(e) => handleChange(e)}
                  type={"number"}
                  name={"awhatsapp"}
                />
              </div>
              <To8a
                placehoder={
                  "Escreva uma breve descrição sobre você e suas principais características*"
                }
                value={escortData.adesc}
                onChange={(e) => handleChange(e)}
                type={"text"}
                name={"adesc"}
              />
            </div>
            {who === "escort" && (
              <>
                <div className="my-5">
                  <To15
                    title={"Localidade"}
                    subtitle={
                      "Preencha os dados que serão exibidos no anúncio."
                    }
                  />

                  <div className="">
                    <To14 text={"Localização"} size={"sm"} />
                    <div className="md:grid md:grid-cols-2 md:gap-x-3">
                      <To8s
                        placehoder={"País*"}
                        value={escortData.lcountry}
                        dropdowns={dropdowns}
                        dropdownId={3}
                        toggleDropdown={toggleDropdown}
                        options={countries}
                        handleSelect={handleSelect}
                        name={"lcountry"}
                      />
                      <To8s
                        placehoder={"Estado*"}
                        value={escortData.lstate}
                        dropdowns={dropdowns}
                        dropdownId={4}
                        toggleDropdown={toggleDropdown}
                        options={states}
                        handleSelect={handleSelect}
                        name={"lstate"}
                      />
                    </div>
                    <To8s
                      placehoder={"Cidade*"}
                      value={escortData.lcity}
                      dropdowns={dropdowns}
                      dropdownId={5}
                      toggleDropdown={toggleDropdown}
                      options={citys}
                      handleSelect={handleSelect}
                      name={"lcity"}
                    />
                  </div>

                  <div className="my-5">
                    <To14 text={"Locais de atendimento"} size={"sm"} />
                    <div className="md:grid md:grid-cols-2">
                      {locations.slice(0, 3).map((location) => (
                        <p key={location.name}>
                          <CheckText
                            text={location.text}
                            value={escortData[location.name]}
                            handleChange={handleCheck}
                            name={location.name}
                          />
                        </p>
                      ))}
                      {locations.slice(3).map((location) => (
                        <p key={location.name}>
                          <CheckText
                            text={location.text}
                            value={escortData[location.name]}
                            handleChange={handleCheck}
                            name={location.name}
                          />
                        </p>
                      ))} 
                    </div>
                  </div>

                  <div className="my-5">
                    <To14 text={"Comodidades dos locais"} size={"sm"} />
                    <div className="md:grid md:grid-cols-2">
                      {amenities.slice(0, 3).map((amenity) => (
                        <p key={amenity.name}>
                          <CheckText
                            text={amenity.text}
                            value={escortData[amenity.name]}
                            handleChange={handleCheck}
                            name={amenity.name}
                          />
                        </p>
                      ))}
                      {amenities.slice(3).map((amenity) => (
                        <p key={amenity.name}>
                          <CheckText
                            text={amenity.text}
                            value={escortData[amenity.name]}
                            handleChange={handleCheck}
                            name={amenity.name}
                          />
                        </p>
                      ))}
                    </div>
                  </div>
                  <div className="my-5">
                    <To14 text={"Informações sobre mim"} size={"sm"} />

                    <div className="md:grid md:grid-cols-2 md:gap-x-3 items-center">
                      {characters.map((field, index) =>
                        field.type !== "boolean" ? (
                          <To8
                            key={index}
                            placehoder={field.placeholder}
                            value={escortData[field.name]}
                            onChange={(e) => handleChange(e)}
                            type={field.type}
                            name={field.name}
                          />
                        ) : (
                          <To8s
                            key={index}
                            placehoder={field.placeholder}
                            value={escortData[field.name]}
                            dropdowns={dropdowns}
                            dropdownId={13 + index}
                            toggleDropdown={toggleDropdown}
                            options={booleanOptions}
                            handleSelect={handleSelect}
                            name={field.name}
                          />
                        )
                      )}
                    </div>
                  </div>

                  <div>
                    <To15
                      title={"Valores e serviços"}
                      subtitle={
                        "Preencha os valores e selecione o serviços que você oferece."
                      }
                    />
                    <div>
                      <To14 text={"Valores"} size={"sm"} />
                      <To30
                        value={values}
                        setValue={setvalues}
                        valors={valorsOptions}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <To14 text={"Forma de pagamento"} size={"sm"} />
                  <div className="md:grid md:grid-cols-2 md:gap-x-3">
                    {paymentMethods.map((method, index) => (
                      <p key={method.name}>
                        <CheckText
                          text={method.text}
                          value={escortData[method.name]}
                          handleChange={handleCheck}
                          name={method.name}
                        />
                      </p>
                    ))}
                  </div>
                </div>
                <div>
                  <To14 text={"Expediente"} size={"sm"} />

                  <div>
                    <To8s
                      placehoder={"Selecione um fuso horário*"}
                      value={escortData.ltimeregion}
                      dropdowns={dropdowns}
                      dropdownId={12}
                      toggleDropdown={toggleDropdown}
                      options={timeRegionsOptions}
                      handleSelect={handleSelect}
                      name={"ltimeregion"}
                    />
                    <To36 value={exp} setValue={setexp} />
                  </div>
                </div>
                <div className="mt-2">
                  <To14 text={"Serviços oferecidos"} size={"sm"} />
                  <div className="md:grid md:grid-cols-2 md:gap-x-3">
                    {activities.slice(0, 7).map((activity, index) => (
                      <p key={activity.id}>
                        <CheckText
                          text={activity.activity}
                          value={escortData[`myservs${index + 1}`]}
                          handleChange={handleCheck}
                          name={`myservs${index + 1}`}
                        />
                      </p>
                    ))}
                    {activities.slice(7).map((activity, index) => (
                      <p key={activity.id}>
                        <CheckText
                          text={activity.activity}
                          value={escortData[`myservs${index + 8}`]}
                          handleChange={handleCheck}
                          name={`myservs${index + 8}`}
                        />
                      </p>
                    ))}
                  </div>
                </div>
                <div className="my-2">
                  <To14 text={"Imagem do anúncio"} size={"sm"} />

                  <To13
                    t1={"Faça upload "}
                    t2={"de uma foto para ser exibida no seu anúncio"}
                    t3={"Limite de 20MB"}
                    type={"file"}
                    id={"foto"}
                    value={foto}
                    setValue={setFoto}
                  />
                </div>
                <div>
                  <To15
                    title={"Galeria"}
                    subtitle={
                      "Adicione as fotos e vídeos que deseja exibir no seu perfil."
                    }
                  />
                  <To25
                    title={"Fotos"}
                    type={"img"}
                    text={"Adicionar foto"}
                    limit={"Limite de 5MB"}
                    value={prevImgs}
                    setValue={setprevImgs}
                    id={"imgs"}
                  />
                  <To25
                    title={"Vídeos"}
                    type={"img"}
                    text={"Adicionar vídeo"}
                    limit={"Limite de 20MB"}
                    value={prevVids}
                    setValue={setprevVids}
                    id={"imgsVid"}
                  />
                  <To21
                    text={"Adicionar conteúdo pago"}
                    desc={
                      "Adicione também conteúdo pago exclusivo e opções de planos de assinatura."
                    }
                  />
                </div>
              </>
            )}
          </>
        )}
        <div>
          <To26 value={contents} setValue={setcontents} obj={obj} />
        </div>{" "}
        <div className="mt-10">
          <div onClick={publishNow} className="t1combtn themecom hover">
            {!loading && (
              <span>{who === "escort" ? "Criar anúncio" : "Publicar"}</span>
            )}
            {loading && <span>loading...</span>}
          </div>
          <div className="grid grid-cols-2 gap-x-3">
            <div onClick={cancelNow} className="t1combtn theme hover">
              Cancelar
            </div>
            <div onClick={saveDraft} className="t1combtn theme btnbtn hover">
              {loading ? "loading..." : "Salvar como rascunho"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step3a;

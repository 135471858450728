import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CookieConsent = () => {
  useEffect(() => {
    // Add the class to body to disable scrolling
    document.body.classList.add("no-scroll");

    // Clean up class when component unmounts or overlay is closed
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);
  const [accepted, setAccepted] = useState(false);

  const storeName = "3zw4xe5rcre6tv87y9urdtfugihjnnn";

  const handleAccept = () => {
    setAccepted(true);
    localStorage.setItem(storeName, "accepted");
  };

  const isAccepted = localStorage.getItem(storeName) === "accepted";

  if (isAccepted || accepted) {
    return null;
  }

  const CardMy = () => {
    return (
      <div className="consent-con bg text-sm">
        <div className=" font-medium mb-5">
          <span className="font-extrabold text-3xl">18+ </span> CONTEÚDO ADULTO
        </div>
        <div className="btc">
          Entendo que o site da peppermodel apresenta 
          <span className="font-semibold">
            conteúdo explícito destinado a adultos.{" "}
          </span>
          <Link to={"/termos"} className="underline block">
            Termos de uso
          </Link>
        </div>
        <div className="border-top-brown border-bottom-brown py-3 my-2">
          <div className="font-semibold mb-2 ">AVISO DE COOKIES</div>
          <div className="btc">
            Nós usamos cookies e outras tecnologias semelhantes para melhorar a
            sua experiência em nosso site.
          </div>
        </div>
        <div className="text-10 btc line-height-1 font-medium">
          A profissão de acompanhante é legalizada no Brasil e deve ser
          respeitada.{" "}
          <Link to={"/"} className="underline">
            Saiba mais
          </Link>
        </div>
        <div
          onClick={handleAccept}
          className="w-full text-center my-3 py-3 rounded-lg themecom hover tcw font-lg font-medium"
        >
          Concordo
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="cookie-overlay "></div>
        <div className="cookie-consent bg">
          <CardMy />
        </div>
      </div>
    </>
  );
};

export default CookieConsent;

import React from 'react'
import { useGeneralBrain } from '../../controller/Brain';
import { ArrowcaRightIcon } from '../../data/Data';

const To7 = ({text}) => {

    const { isMobile } = useGeneralBrain();
  return (
    <>
        {!isMobile && (
            <div className="flex gap-5 text-xs">
              <span>Página inicial </span>
              <span>
                <ArrowcaRightIcon size={10} color = "var(--white)"/>
              </span>
              <span>{text}</span>
            </div>
          )}
    </>
  )
}

export default To7
import React, { createContext, useState, useContext, useEffect } from "react";
import { auth, db } from "../utils/init-firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  deleteUser,
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import {
  doc,
  runTransaction,
  serverTimestamp,
  getDoc,
  onSnapshot,
  deleteField,
  arrayRemove,
} from "firebase/firestore";
import Swal from "sweetalert2";
import { Toast, showConfirmDialog } from "../controller/Brain";

const AuthContext = createContext({
  currentUser: null,
  register: () => Promise,
  login: () => Promise,
  logout: () => Promise,
});

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);

  const generateUid8 = () => {
    const timestamp = new Date().getTime().toString(36);
    const randomString1 = Math.random().toString(36).substr(2, 3);
    const randomString2 = Math.random().toString(36).substr(2, 3);
    const randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomPart = "";
    for (let i = 0; i < 2; i++) {
      randomPart += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return `${timestamp}-${randomString1}-${randomString2}-${randomPart}`;
  };

  const [isloading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  async function handleForgotPassword(email) {
    try {
      await sendPasswordResetEmail(auth, email);

      Toast.fire("Password Reset Email Sent").then(() => {
        console.log("Redirecting to login page...");
      });
    } catch (error) {
      console.error("Password reset failed:", error);
      Swal.fire({
        title: "Password Reset Failed",
        text: "An error occurred. Please try again.",
        icon: "error",
      });
    }
  }

  async function register({
    email,
    password,
    fullname,
    username,
    who,
    create,
    userId,
    emailVerified,
  }) {
    try {
      await runTransaction(db, async (transaction) => {
        let result;
        if (create) {
          result = await createUserWithEmailAndPassword(auth, email, password);
        }

        const uid = create ? result.user.uid : userId;

        const userData = {
          userId: uid,
          userEmail: email,
          userName: username,
          userFullname: fullname,
          emailVerified: emailVerified || false,
          imgPfp: "",
          adob: "",
          aservice: "",
          atelephone: "",
          awhatsapp: "",
          adesc: "",

          lcountry: "",
          lstate: "",
          lcity: "",
          isMember: true,
          isOnline: serverTimestamp(),
          isSubcribed: false,
          isSubcribedStatus: "",
          isAdmin: false,
          isVerified: false,
          isVerifiedStatus: "under review",
          joinDate: serverTimestamp(),
          who: who,
          myPlan: "free",
          myRate: 0,
          myReviews: 0,
          myContents: 0,
          myFollowers: 0,
          myFollowings: [],
          myGender: "",
          status: "new",
          todo: who === "client" ? "" : "verify",
        };

        const userInfo = {
          userId: uid,
          userEmail: email,
          userName: fullname,
          username: username,
          who: who,
        };

        const documentRefs = [
          { ref: doc(db, "users", uid), data: userData },
          { ref: doc(db, "usernames", username), data: userInfo },
          { ref: doc(db, "useremails", email), data: userInfo },
          { ref: doc(db, "userVerifications", uid), data: {} },
          { ref: doc(db, "escorts", uid), data: {} },
          { ref: doc(db, "escortContents", uid), data: {} },
          { ref: doc(db, "escortReviews", uid), data: {} },
        ];

        for (const { ref, data } of documentRefs) {
          transaction.set(ref, data);
        }

        const uniqueLogId = generateUid8();
        const logRef = doc(db, "admin", "logs");
        transaction.update(logRef, {
          [uniqueLogId]: {
            info: `New user just register ${email}`,
            time: serverTimestamp(),
            username: username,
          },
        });

        // const emailRef = collection(db, "sendEmails");
        // const docRef = doc(emailRef);
        // transaction.set(docRef, {
        //   recipient: email,
        //   subject: "Welcome to peppermodel",
        //   title: `Hi ${name}`,
        //   content: `Welcome to peppermodel. Good to have you here`,
        //   link: "nevewanisamaj.in",
        //   timestamp: serverTimestamp(),
        // });

        setLoading(false);
      });
    } catch (error) {
      console.error("Registration failed:", error);
      if (error.code === "auth/email-already-in-use") {
        Toast.fire({
          title: "Unable to register",
          text: "Email already exists. Please log in.",
          icon: "error",
        });
      } else {
        Toast.fire({
          title: "Registration failed",
          text: "An error occurred. Please try again.",
          icon: "error",
        });
      }
      setLoading(false);
      throw error;
    }
  }

  async function login(email, password) {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential;
  }

  async function SignWithGoogle() {
    const auth = getAuth();

    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then((result) => {
      GoogleAuthProvider.credentialFromResult(result);
    });
  }

  async function logout() {
    try {
      await signOut(auth);
      setCurrentUser(null);
    } catch (error) {
      console.error("Logout failed:", error);
      throw error;
    }
  }

  async function deleteUserAccount(id) {
    const isConfirmed = await showConfirmDialog({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
    });

    if (isConfirmed) {
      try {
        const userRef = doc(db, "users", id);
        const userDocSnap = await getDoc(userRef);

        if (!userDocSnap.exists()) {
          throw new Error("No document found");
        }

        const theData = userDocSnap.data();

        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          const credential = getEmailPasswordCredential(
            theData.userEmail,
            theData.userPassword
          );

          await reauthenticateUser(user, credential);

          await runTransaction(db, async (transaction) => {
            if (theData?.isSubcribed) {
              const live1 =
                theData.gender === "Male" ? "livemale1" : "livefemale1";
              const liveMatrimonysRef = doc(db, "liveMatrimonys", live1);
              transaction.update(liveMatrimonysRef, {
                [theData.userId]: deleteField(),
              });

              const liveShortlistIdsRef = doc(
                db,
                "liveShortlistIds",
                theData.userId
              );
              transaction.delete(liveShortlistIdsRef);

              const liveShortlistsRef = doc(
                db,
                "liveShortlists",
                theData.userId
              );
              transaction.delete(liveShortlistsRef);

              const matrimonysRef = doc(db, "matrimonys", theData.userId);
              transaction.delete(matrimonysRef);

              const userRef = doc(db, "users", theData.userId);
              transaction.update(userRef, {
                isSubcribed: false,
                isSubcribedStatus: "deleted",
              });

              const emailIdRef = doc(db, "mails", "matrimonys");
              transaction.update(emailIdRef, {
                admins: arrayRemove(theData.userEmail),
              });

              const code = "code";
              const dociRef = doc(db, "info", "users");
              transaction.update(dociRef, {
                [`${id}.${code}`]: 1111111,
              });
            }

            const userDocRef = doc(db, "users", theData.userId);
            transaction.delete(userDocRef);

            const userNicknameDocRef = doc(
              db,
              "userNicknames",
              theData.userNickname
            );
            transaction.delete(userNicknameDocRef);

            const userEmailsDocRef = doc(db, "userEmails", theData.userEmail);
            transaction.delete(userEmailsDocRef);

            const emailIdRef = doc(db, "mails", "users");
            transaction.update(emailIdRef, {
              admins: arrayRemove(theData.userEmail),
            });

            const adduserRef = doc(db, "info", "users");
            transaction.update(adduserRef, {
              [theData.userId]: deleteField(),
            });

            if (theData.isAdmin) {
              const adminRef = doc(db, "info", "admins");
              transaction.update(adminRef, {
                [theData.userId]: deleteField(),
              });

              const emailIdRef = doc(db, "mails", "admins");
              transaction.update(emailIdRef, {
                admins: arrayRemove(theData.userEmail),
              });
            }
          });

          await deleteUser(user);
          window.location.href = "/login";
          Toast.fire({
            icon: "success",
            title: "Account has been deleted successfully",
          });
        } else {
          Swal.fire("Error!", "No user is currently signed in.", "error");
        }
      } catch (error) {
        console.error("Error deleting account:", error);
        Swal.fire("Error!", "Please try again.", "error");
        throw error;
      }
    }
  }

  async function deleteAdminUserAccount(id) {
    const isConfirmed = await showConfirmDialog({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
    });

    if (isConfirmed) {
      try {
        const userRef = doc(db, "users", id);
        const userDocSnap = await getDoc(userRef);

        if (!userDocSnap.exists()) {
          throw new Error("No document found");
        }

        const theData = userDocSnap.data();

        await runTransaction(db, async (transaction) => {
          if (theData?.isSubcribed) {
            const live1 =
              theData.gender === "Male" ? "livemale1" : "livefemale1";
            const liveMatrimonysRef = doc(db, "liveMatrimonys", live1);
            transaction.update(liveMatrimonysRef, {
              [theData.userId]: deleteField(),
            });

            const liveShortlistIdsRef = doc(
              db,
              "liveShortlistIds",
              theData.userId
            );
            transaction.delete(liveShortlistIdsRef);

            const liveShortlistsRef = doc(db, "liveShortlists", theData.userId);
            transaction.delete(liveShortlistsRef);

            const matrimonysRef = doc(db, "matrimonys", theData.userId);
            transaction.delete(matrimonysRef);

            const userRef = doc(db, "users", theData.userId);
            transaction.update(userRef, {
              isSubcribed: false,
              isSubcribedStatus: "deleted",
            });

            const emailIdRef = doc(db, "mails", "matrimonys");
            transaction.update(emailIdRef, {
              admins: arrayRemove(theData.userEmail),
            });

            const code = "code";
            const dociRef = doc(db, "info", "users");
            transaction.update(dociRef, {
              [`${id}.${code}`]: 1111111,
            });
          }

          const userDocRef = doc(db, "users", theData.userId);
          transaction.update(userDocRef, {
            status: "deleted",
            isMember: false,
          });

          const userNicknameDocRef = doc(db, "userNicknames", theData.userName);

          if (theData.isAdmin) {
            const adminRef = doc(db, "info", "admins");
            transaction.update(adminRef, {
              [theData.userId]: deleteField(),
            });
          }

          transaction.delete(userNicknameDocRef);

          const userEmailsDocRef = doc(db, "userEmails", theData.userEmail);
          transaction.delete(userEmailsDocRef);

          const emailIdRef = doc(db, "mails", "users");
          transaction.update(emailIdRef, {
            admins: arrayRemove(theData.userEmail),
          });

          const adduserRef = doc(db, "info", "users");
          transaction.update(adduserRef, {
            [theData.userId]: deleteField(),
          });
        });

        Toast.fire({
          icon: "success",
          title: "User been deleted successfully",
        });
      } catch (error) {
        console.error("Error deleting account:", error);
        Swal.fire("Error!", "Please try again.", "error");
        throw error;
      }
    }
  }

  function getEmailPasswordCredential(email, password) {
    return EmailAuthProvider.credential(email, password);
  }

  async function reauthenticateUser(user, credential) {
    try {
      await reauthenticateWithCredential(user, credential);
    } catch (error) {
      console.error("Error re-authenticating user:", error);
      throw error;
    }
  }

  async function UpdateUserPassword(currentPassword, newPassword) {
    const isConfirmed = await showConfirmDialog({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
    });

    if (isConfirmed) {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          const credential = getEmailPasswordCredential(
            userData.userEmail,
            currentPassword
          );

          await reauthenticateUser(user, credential);
          await updatePassword(user, newPassword);

          await runTransaction(db, async (transaction) => {
            const userDocRef = doc(db, "users", userData.userId);
            transaction.update(userDocRef, {
              userPassword: newPassword,
            });
          });

          Toast.fire({
            icon: "success",
            title: "Password updated successfully",
          });
        } else {
          Swal.fire("Error!", "No user is currently signed in.", "error");
        }
      } catch (error) {
        console.error("Error updating password:", error);
        Swal.fire("Error!", "Please try again.", "error");
      }
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setLoading(false);
      }
      if (!user) {
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (currentUser) {
          const userQuery = doc(db, "users", currentUser.uid);
          const userDocSnapshot = await getDoc(userQuery);
          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            setUserData(userData);
            setLoading(false);
          } else {
            const whoa = localStorage.getItem("mywho") || "client";
            register({
              email: currentUser.email,
              fullname: currentUser.displayName,
              username: currentUser.email.replace("@gmail.com", ""),
              userId: currentUser.uid,
              who: whoa,
              create: false,
              emailVerified: currentUser.emailVerified,
            });

            localStorage.removeItem("mywho");
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };

    const unsubscribe =
      currentUser &&
      onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        const userData = doc.data();
        setUserData(userData);
      });

    fetchUserData();

    return () => {
      unsubscribe && unsubscribe();
    };

    /* eslint-disable-next-line */
  }, [currentUser]);

  const value = {
    currentUser,
    setCurrentUser,
    register,
    login,
    SignWithGoogle,
    logout,
    deleteUserAccount,
    deleteAdminUserAccount,
    UpdateUserPassword,
    isloading,
    setLoading,
    userData,
    handleForgotPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

import React from "react";
import To22 from "../../cards/To22";
import To23 from "../../cards/To23";

const Step1 = ({
  fullname,
  setFullname,
  efullname,
  username,
  setUsername,
  eusername,
  email,
  setRegisterEmail,
  eregisterEmail, 
  password,
  setRegisterPassword,
  eregisterPassword,
  confirmPassword,
  setConfirmPassword,
  econfirmPassword,
  terms,
  setTerms,
  ager,
  setAger,
  s1,
  s2,
  s3,
  sets1,
  sets2,
  sets3,
  handleRegister,
  loading,
}) => {
  return (
    <>
      <div>
        <To23
          who={"seller"}
          fullname={fullname}
          setFullname={setFullname}
          efullname={efullname}
          username={username}
          setUsername={setUsername}
          eusername={eusername}
          email={email}
          setRegisterEmail={setRegisterEmail}
          eregisterEmail={eregisterEmail}
          password={password}
          setRegisterPassword={setRegisterPassword}
          eregisterPassword={eregisterPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          econfirmPassword={econfirmPassword}
        />
        <To22
          s1={s1}
          s2={s2}
          s3={s3}
          sets1={sets1}
          sets2={sets2}
          sets3={sets3}
          ager={ager}
          setAger={setAger}
          terms={terms}
          setTerms={setTerms}
          loading={loading}
          handleRegister={handleRegister}
        />
      </div>
    </>
  );
};

export default Step1;

import React, { useContext } from "react";
import { ProjectName } from "../data/Data";
import { Link } from "react-router-dom";
import FootSocial from "./cards/FootSocial";
import ThemeToggle from "./general/ThemeToggle";
import { ThemeContext } from "../contexts/ThemeContext";
import l1 from "../assets/l1.png";
import l2 from "../assets/l2.png";
import { useGeneralBrain } from "../controller/Brain";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const { theme } = useContext(ThemeContext);
  const { isMobile } = useGeneralBrain();

  return (
    <div className="z-50 bg sticky bottom-0 left-0 right-0">
      <div className="theme-gap z-50"></div>
      <div className="mx-5 footer-container foottext z-50">
        {isMobile && (
          <div className="flex justify-center flex-col">
            <div className="mt-8 mb-2">
              <img
                src={theme === "light" ? l2 : l1}
                alt="logo"
                className="navbar-logo -ml-2"
              />
            </div>
            <FootSocial />
            <div className="text-sm">
              ©{currentYear}. {ProjectName}.com.
            </div>
            <div className="text-sm mb-5">All rights reserved.</div>

            <div className="flex  mb-14">
              <Link to={"/termos"} className="foot-list-link mr-5">
                Termos de uso
              </Link>
              <Link to={"/política"} className="foot-list-link">
                Política de privacidade
              </Link>
            </div>
          </div>
        )}
        {!isMobile && (
          <div className="mt-12 mx-10 z-50">
            <div className="sm:flex lg:gap-40 md:gap-20">
              <div className="">
                <img
                  src={theme === "light" ? l2 : l1}
                  alt="logo"
                  className="navbar-logo -ml-2"
                />
              </div>
              <div className="foot-list">
                <Link to={"/acompanhantes"} className="foot-list-link">
                  Conheça a peppermodel
                </Link>
                <Link to={"/register"} className="foot-list-link">
                  Cadastre-se
                </Link>
                <Link to={"/planos"} className="foot-list-link">
                  Planos
                </Link>
                <Link to={"/planos#profit-cal"} className="foot-list-link">
                  Simule seus ganhos
                </Link>
                <Link to={"/blog"} className="foot-list-link">
                  Blog
                </Link>
                <Link to={"/#faq"} className="foot-list-link">
                  Dúvidas frequentes
                </Link>
              </div>
              <div className="foot-list">
                <Link to={"/política"} className="foot-list-link">
                  Política de privacidade
                </Link>
                <Link to={"/termos"} className="foot-list-link">
                  Termos de uso
                </Link>
                <Link to={"/contato"} className="foot-list-link">
                  Fale conosco!
                </Link>
                <Link to={"/denunciar"} className="foot-list-link">
                  Denunciar exploração sexual
                </Link>
              </div>
              <div className="foot-list">
                <div className="foot-list-link btc opacity-60">Regiões</div>
                <Link to={"/acompanhantes-norte"} className="foot-list-link">
                  Norte
                </Link>
                <Link to={"/acompanhantes-nordeste"} className="foot-list-link">
                  Nordeste
                </Link>
                <Link
                  to={"/acompanhantes-centro-oeste"}
                  className="foot-list-link"
                >
                  Centro-oeste
                </Link>
                <Link to={"/acompanhantes-sudeste"} className="foot-list-link">
                  Sudeste
                </Link>
                <Link to={"/acompanhantes-sul"} className="foot-list-link">
                  Sul
                </Link>
              </div>
            </div>
            <div className="flex justify-between foot-border mt-5">
              <div className="text-sm">
                ©{currentYear}. {ProjectName}.com. All rights reserved.
              </div>

              <FootSocial />
            </div>
          </div>
        )}
      </div>
      <ThemeToggle />
    </div>
  );
};

export default Footer;

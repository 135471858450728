import React, { useEffect, useState } from "react";
import To5 from "../cards/To5";
import To7 from "../cards/To7";
import Slider1 from "../../controller/Slider";
import { Slider } from "infinite-react-carousel";
// import { doc, runTransaction, serverTimestamp } from "firebase/firestore";
// import { db } from "../../utils/init-firebase";
// import { Toast } from "../../controller/Brain";
// import { generateUid8 } from "../../controller/Functions";

const Section1 = ({ isMobile, loading, data, notFound }) => {
  // Function to generate a random user with 1 or 2 statuses
  // const generateUsersWithStatus = () => {
  //   const users = {};

  //   for (let i = 0; i < 15; i++) {
  //     const userId = generateUid8();
  //     const userFullName = `User FullName ${i + 1}`;
  //     const username = `user${i + 1}`;
  //     const pfpImg = pfpImages[Math.floor(Math.random() * pfpImages.length)];
  //     const numStatuses = Math.floor(Math.random() * 2) + 1; // Random 1 or 2 statuses
  //     const userTimestamp = serverTimestamp(); // Set timestamp outside the array
  //     const status = {}; // Now an object instead of an array

  //     for (let j = 0; j < numStatuses; j++) {
  //       const statusId = `status-${i + 1}-${j + 1}`;
  //       const type = Math.random() > 0.5 ? 'image' : 'video';
  //       const url = type === 'image'
  //         ? postImages[Math.floor(Math.random() * postImages.length)]
  //         : videoUrl;

  //       const likes = Math.floor(Math.random() * 1000);
  //       const views = Math.floor(Math.random() * 1000);

  //       // Add each status object with statusId as the key
  //       status[statusId] = {
  //         time: userTimestamp, // Use the timestamp set earlier
  //         type,
  //         url,
  //         likes,
  //         views
  //       };
  //     }

  //     // Store each user by their userId as the key and the rest of the user data as the value
  //     users[userId] = {
  //       pfpImg,
  //       username,
  //       userFullName,
  //       status // Now an object of statuses
  //     };
  //   }

  //   return users;
  // };

  // const updateNow = async () => {
  //   try {
  //     const newData = generateUsersWithStatus();

  //     await runTransaction(db, async (transaction) => {
  //       const userDocRef = doc(db, "lives", 'sao-paulo-sp-escort-womens-status');
  //       console.log(newData)
  //       transaction.update(userDocRef, newData);

  //     }).then(
  //       Toast.fire({
  //         icon: "success",
  //         title: `Done successfully`,
  //       })
  //     );
  //   } catch (error) {
  //     console.error("Error here:", error);
  //   }
  // };

  const [myArrays, setmyArrays] = useState([]);

  useEffect(() => {
    if (data) {
      const myArray = Object.entries(data).map(([dataId, data]) => ({
        ...data,
        id: dataId,
      }));
      setmyArrays(myArray);
    }

    /* eslint-disable-next-line */
  }, [data]);

  const MySvg = ({ data }) => {
    return (
      <>
        <div className="lg:grid lg:grid-cols-9 gap-x-5 lg:gap-x-8 ">
          {data.map((d) => (
            <div key={d.id}>
              <To5
                data={myArrays}
                id={d.id}
                name={d.userFullName}
                img={d.pfpImg}
                key={d.id}
              />
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="background-container tcw py-5 sm:pb-10 px-5">
      <To7 text={"Acompanhantes"} />
      <div className="sm:px-20">
        <div className="font-bold mb-10 text-3xl sm:mt-5 sm:pl-10">
          Encontre acompanhantes
        </div>

        {myArrays?.length > 0 && (
          <div>
            <div className="hidden lg:block lg:px-10">
              <Slider1
                svg={MySvg}
                dots={false}
                data={myArrays}
                length={9}
                theme={true}
              />
            </div>

            <div className="lg:hidden">
              <Slider
                slidesToShow={isMobile ? 3 : 7}
                arrows={false}
                autoplay={true}
                autoplaySpeed={4000}
              >
                {myArrays.map((d) => (
                  <div key={d.id}>
                    <To5
                      name={d.userFullName}
                      img={d.pfpImg}
                      key={d.id}
                      data={myArrays}
                      id={d.id}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Section1;

import React from "react";
import ImgSvg from "./ImgSvg";

const To6 = ({ img, svg:Svg, sm, text, size, theme, cols, color, lg }) => {

  const colors = cols ? cols : 'btc'
  return (
    <div className="flex items-center gap-2 capitalize font-semibold">
      <div className="w-3 flex justify-center items-center">
        {img && <ImgSvg img={img} />}
        {Svg && <Svg  size={size} color={color}/>} 
      </div>
      <div className={`${colors} ${sm ? 'text-10' : "text-sm"} ${theme ? 'theme underline hover' : ""} `}>{text}</div>
    </div>
  );
};

export default To6;

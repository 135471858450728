import React from "react";
import { useGeneralBrain } from "./Brain";
import ProfileCard from "../components/cards/ProfileCard";
import Slider from "./Slider";
import To14 from "../components/cards/To14";

const Recommend = ({ who }) => {
  const { isMobile } = useGeneralBrain();

  const myArrays = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const MySvg = ({ data }) => {
    return (
      <>
        <div className="flex justify-start items-center text-start">
          <To14 text={"Recomendações"} tc={"tc"} size={"sm"} />
        </div>
        <div className="grid grid-cols-3 gap-x-5 my-5">
          {data.map((card, index) => (
            <div key={index} className="mr-3">
              <ProfileCard
                who={who}
                key={index}
                plan={"free"}
                name={"Julia Gomes"}
                price={250}
                rating={4.8}
                age={32}
                sex={"female"}
                lastOnline={"Online na 8 minuties"}
                city={"Santos-SP"}
                followers={653}
                ownplace={true}
                profile={"Apenas homens"}
                telephone={"23455"}
                username={"maria"}
                sm={true}
                md={true}
              />
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="">
      {!isMobile && (
        <Slider
          who={who}
          data={myArrays}
          svg={MySvg}
          length={3}
          className={" "}
        />
      )}
      {isMobile && (
        <>
          <To14 text={"Recomendações"} tc={"tc"} size={"sm"} />
          <div className={`my-5 sm:my-10 flex items-center overflow-x-scroll`}>
            {myArrays.slice(0, 9).map((card, index) => (
              <div key={index} className="mr-5">
                <ProfileCard
                  who={who}
                  key={index}
                  plan={"free"}
                  name={"Julia Gomes"}
                  price={250}
                  rating={4.8}
                  age={32}
                  sex={"female"}
                  lastOnline={"Online na 8 minuties"}
                  city={"Santos-SP"}
                  followers={653}
                  ownplace={true}
                  profile={"Apenas homens"}
                  telephone={"23455"}
                  username={"maria"}
                  sm={true}
                  md={true}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Recommend;

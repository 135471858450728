import React from "react";
import { Love1Icon, PeepIcon, PFPIcon, Share1Icon } from "../../data/Data";
import { TimeAgo, ToProfile } from "../../controller/Functions";
import { Link } from "react-router-dom";
import { IconText } from "../../controller/components";

const PostCard = ({ data }) => {


  return (
    <div className="btnbtn21 p-5 rounded-2xl mb-5">
      <Link to={ToProfile(data.u)}>
        <div className="flex items-center mb-5 sm:mb-5">
          <PFPIcon />
          <div className="font-bold texst-sm ml-1 capitalize">{data?.n}</div>
        </div>
      </Link>
      <div className="w-full h-96 bglb height-lg">
        {data.type === "image" && (
          <img
            src={data.url}
            alt=""
            className="object-cover bglb w-full h-full"
          />
        )}
        {data.type === "video" && (
          <video
            src={data.url}
            alt=""
            className="object-cover bglb w-full h-full"
            controls
          />
        )}
      </div>
      <div className="flex justify-between items-center my-5">
        <div className="flex justify-between items-center">
          {data.l} <IconText svg={Love1Icon} text={"Curtir"} />
        </div>
        <IconText svg={Share1Icon} text={"Compartilhar"} />
        <Link to={ToProfile(data.u)}>
          {" "}
          <IconText svg={PeepIcon} text={"Ver perfil"} />
        </Link>
      </div>
      <div className="text-sm font-normal">Postado {TimeAgo(data.t)}</div>
    </div>
  );
};

export default PostCard;

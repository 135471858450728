import React from "react";
import { formatName } from "../../controller/Functions";
import { useGeneralBrain } from "../../controller/Brain";

const To5 = ({ name, img, id, data }) => {
  const { setShowFullPage, setStatusData, setStatusId } = useGeneralBrain();

  const handle = async () => {
    setStatusData(data);
    setStatusId(id);
    setShowFullPage(true);
  };

  return (
    <>
      <div className="flex flex-col gap-3 text-center cursor-pointer">
        <div onClick={handle} className="t05-con">
          <img src={img} alt="profile img" className="t05-con-img bglb" />
        </div>
        <div className="text-sm capitalize my-0.5">{formatName(name)}</div>
      </div>
    </>
  );
};

export default To5;

export const activities = [
  {
    id: "SO",
    activity: "Faz sexo oral sem camisinha",
    cat: ["women", "men", "trans"],
  },
  {
    id: "RA",
    activity: "Recebe sexo anal com camisinha",
    cat: ["women", "men", "trans"],
  },
  { id: "RV", activity: "Recebe sexo vaginal com camisinha", cat: ["women"] },
  { id: "ST", activity: "Faz striptease", cat: ["women", "men", "trans"] },
  { id: "FM", activity: "Faz masturbação", cat: ["women", "men", "trans"] },
  {
    id: "TT",
    activity: "Faz massagem tântrica",
    cat: ["women", "men", "trans"],
  },
  {
    id: "OC",
    activity: "Faz sexo oral com camisinha",
    cat: ["women", "men", "trans"],
  },
  { id: "DO", activity: "Faz dominação", cat: ["women", "men", "trans"] },
  {
    id: "PB",
    activity: "Faz penetração com brinquedos sexuais",
    cat: ["women", "men", "trans"],
  },
  { id: "FP", activity: "Faz fetiche por pés", cat: ["women", "men", "trans"] },
  { id: "BO", activity: "Faz bondage", cat: ["women", "men", "trans"] },
  { id: "FI", activity: "Faz fisting", cat: ["women", "men", "trans"] },
  { id: "GD", activity: "Dá chuva dourada", cat: ["women", "men", "trans"] },
  { id: "VO", activity: "Voyeurismo", cat: ["women", "men", "trans"] },
  { id: "OU", activity: "Outro", cat: ["women", "men", "trans"] },
];


export const expediente = [
  { day: "Segunda-feira", start: "", end: "", work: true },
  { day: "Terça-feira", start: "", end: "", work: true },
  { day: "Quarta-feira", start: "", end: "", work: true },
  { day: "Quinta-feira", start: "", end: "", work: true },
  { day: "Sexta-feira", start: "", end: "", work: true },
  { day: "Sábado", start: "", end: "", work: true },
  { day: "Domingo", start: "", end: "", work: true },
]
export const locations = [
  { text: "Local próprio", name: "slown" },
  { text: "Hotéis", name: "slhotel" },
  { text: "Motéis", name: "slmotel" },
  { text: "Festas e eventos", name: "slevent" },
  { text: "A domicílio", name: "slhome" },
  { text: "Outro", name: "slother" },
];

export const amenities = [
  { text: "Ar-condicionado", name: "sgac" },
  { text: "Chuveiro", name: "sgshower" },
  { text: "Frigobar", name: "sgminibar" },
  { text: "Wi-fi", name: "sgwifi" },
  { text: "Televisão", name: "sgtv" },
  { text: "Estacionamento", name: "sgparking" },
];

export const paymentMethods = [
  { text: "Dinheiro", name: "pmmoney" },
  { text: "PIX", name: "pmpix" },
  { text: "Crédito", name: "pmcredit" },
  { text: "Débito", name: "pmdebit" },
];

export const regionsOptions = [
  { name: "Norte", value: "norte", info: "N", url: "/acompanhantes-norte" },
  {
    name: "Nordeste",
    value: "nordeste",
    info: "NE",
    url: "/acompanhantes-nordeste",
  },
  {
    name: "Centro-Oeste",
    value: "centro-oeste",
    info: "CO",
    url: "/acompanhantes-centro-oeste",
  },
  {
    name: "Sudeste",
    value: "sudeste",
    info: "SE",
    url: "/acompanhantes-sudeste",
  },
  { name: "Sul", value: "sul", info: "S", url: "/acompanhantes-sul" },
];

export const sexOptions = [
  { name: "male", value: "male", info: "M" },
  { name: "female", value: "female", info: "F" },
  { name: "Gay", value: "gay", info: "G" },
];

export const timeRegionsOptions = [
  { name: "UTC -12:00", value: "utc-12", info: "UTC-12" },
  { name: "UTC -11:00", value: "utc-11", info: "UTC-11" },
  { name: "UTC -10:00", value: "utc-10", info: "UTC-10" },
  { name: "UTC -09:00", value: "utc-9", info: "UTC-9" },
  { name: "UTC -08:00", value: "utc-8", info: "UTC-8" },
  { name: "UTC -07:00", value: "utc-7", info: "UTC-7" },
  { name: "UTC -06:00", value: "utc-6", info: "UTC-6" },
  { name: "UTC -05:00", value: "utc-5", info: "UTC-5" },
  { name: "UTC -04:00", value: "utc-4", info: "UTC-4" },
  { name: "UTC -03:00", value: "utc-3", info: "UTC-3" },
  { name: "UTC -02:00", value: "utc-2", info: "UTC-2" },
  { name: "UTC -01:00", value: "utc-1", info: "UTC-1" },
  { name: "UTC 00:00", value: "utc", info: "UTC" },
  { name: "UTC +01:00", value: "utc+1", info: "UTC+1" },
  { name: "UTC +02:00", value: "utc+2", info: "UTC+2" },
  { name: "UTC +03:00", value: "utc+3", info: "UTC+3" },
  { name: "UTC +04:00", value: "utc+4", info: "UTC+4" },
  { name: "UTC +05:00", value: "utc+5", info: "UTC+5" },
  { name: "UTC +06:00", value: "utc+6", info: "UTC+6" },
  { name: "UTC +07:00", value: "utc+7", info: "UTC+7" },
  { name: "UTC +08:00", value: "utc+8", info: "UTC+8" },
  { name: "UTC +09:00", value: "utc+9", info: "UTC+9" },
  { name: "UTC +10:00", value: "utc+10", info: "UTC+10" },
  { name: "UTC +11:00", value: "utc+11", info: "UTC+11" },
  { name: "UTC +12:00", value: "utc+12", info: "UTC+12" },
];

export const valorsOptions = [
  { name: "1 hora", value: "1 hora", info: "1" },
  { name: "30 minutos", value: "30 minutos", info: "F" },
  { name: "4 horas", value: "4 horas", info: "G" },
  { name: "2 horas", value: "2 horas", info: "G" },
  { name: "Pernoite", value: "Pernoite", info: "G" },
  { name: "Diária", value: "Diária", info: "G" },
  { name: "Diário de viagem", value: "Diário de viagem", info: "G" },
];

export const serviceOptions = [
  { name: "escort", value: "escort", info: "E" },
  { name: "content", value: "content", info: "C" },
];

export const timeOptions = [
  { name: "5 minutos", value: 5, info: "5" },
  { name: "8 minutos", value: 8, info: "8" },
  { name: "10 minutos", value: 10, info: "10" },
  { name: "12 minutos", value: 12, info: "12" },
  { name: "15 minutos", value: 15, info: "15" },
];

export const categoriaOptions = [
  { name: "Populares", value: "populares", info: "p" },
  { name: "Recentes", value: "recentes", info: "r" },
  { name: "Entrada", value: "entrada", info: "e" },
  { name: "Notícias", value: "notícias", info: "n" },
];
export const booleanOptions = [
  { name: "Yes", value: true, info: "y" },
  { name: "No", value: false, info: "n" },
];

export const characters = [
  {
    placeholder: "Preferência sexual*",

    type: "text",
    name: "chapresex",
  },
  {
    placeholder: "Altura (m)*",

    type: "number",
    name: "chaheight",
  },
  {
    placeholder: "Peso (kg)*",

    type: "number",
    name: "chaweight",
  },
  {
    placeholder: "Cor dos olhos*",

    type: "text",
    name: "chaeyecolor",
  },
  {
    placeholder: "Etnia*",

    type: "text",
    name: "chaethnicity",
  },
  {
    placeholder: "Tamanho de cabelo*",

    type: "text",
    name: "chahairsize",
  },
  {
    placeholder: "Estilo de cabelo*",

    type: "text",
    name: "chahairstyle",
  },
  {
    placeholder: "Silicone*",

    type: "boolean",
    name: "chasilicone",
  },
  {
    placeholder: "Tamanho do pé*",

    type: "number",
    name: "chafootsize",
  },
  {
    placeholder: "Piercings*",

    type: "boolean",
    name: "chapiercing",
  },
  {
    placeholder: "Tatuagens*",

    type: "boolean",
    name: "chatatoo",
  },
  {
    placeholder: "Idiomas*",

    type: "text",
    name: "chalanguage",
  },
];

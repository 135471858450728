import React from "react";
import { ArrowcaLeftIcon, ArrowcaRightIcon, ThreeDotIcon } from "../data/Data";

const Pagination = () => {
  let isRight = true;
  let isLeft = false;
  let more5 = true;
  return (
    <div className="flex btc text-sm">
      {more5 && <ArrowcaLeftIcon size={12} color={isLeft ? "" : " #D9D9D9"} />}
      <div className=''>1 2 3</div>
      {more5 && <ThreeDotIcon size={14} color={"var(--textColor)"} />}
      {5}
      {more5 && <ArrowcaRightIcon size={14} color={isRight ? "" : "#D9D9D9"} />}
    </div>
  );
};

export default Pagination;

import React from "react";
import { useParams } from "react-router-dom";
import Container from "./Container";
import Regioes from "../pages/user/Regioes";
import NotFound from "../pages/NotFound";
import Acompanhantes from "../pages/user/Escorts";
import { regions, states } from "../data/Brazil";
import AcompanEncontre from "../pages/user/Posts";

const Roots = () => {
  const { query } = useParams();

  const isPost = query.startsWith("posts-");
  const isEscort = query.startsWith("acompanhantes-");

  if (!query) {
    // If the query doesn't start with "acompanhantes", return 404
    return <Container card={NotFound} path={"home"} title={"Not Found Page"} />;
  }

  // Extract the region/state part after "acompanhantes-"
  const queryValue = query.replace("acompanhantes-", "");

  // Check if the query matches a region
  if (regions.includes(queryValue)) {
    return (
      <Container
        region={queryValue}
        card={Regioes}
        path={"home"}
        title={`Regiões ${queryValue}`}
      />
    );
  }

  // Check if the query ends with a valid state abbreviation (sigla)
  const lastPart = queryValue.split("-").pop(); // Get the last part of the query

  const matchedState = states.find(
    (state) => state.Sigla.toLowerCase() === lastPart.toLowerCase()
  );

  if (matchedState && isEscort) {
    // If a valid state abbreviation is found, return the Acompanhantes component
    return (
      <Container
        query={queryValue}
        card={Acompanhantes}
        path={"home"}
        title={`Acompanhantes - ${matchedState.Nome}`}
      />
    );
  } else if (matchedState && isPost) {
    // If a valid state abbreviation is found, return the Acompanhantes component
    return (
      <Container
        query={queryValue.replace("posts-", "")}
        card={AcompanEncontre}
        path={"home"}
        title={`Posts - ${matchedState.Nome}`}
      />
    );
  }

  // If no region or state is matched, return 404
  return <Container card={NotFound} path={"home"} title={"Not Found Page"} />;
};

export default Roots;

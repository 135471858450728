import React, { useEffect, useState } from "react";
import { Filter1Icon, SearchIcon, SortIcon } from "../data/Data";
import BarNav from "../components/cards/BarNav";
import { LocationBtn } from "./components";
import ShowFilter from "../components/filter/ShowFilter";
import ShowOrder from "../components/filter/ShowOrder";
import Overlay from "../components/general/Overlay";
import Location from "./Location";
import { getCityFromUrl } from "./Functions";
import { useLocation } from "react-router-dom";

const Filter = ({
  active,
  who,
  setactive,
  allData,
  setMyData,
  loading,
  error,
}) => {
  useEffect(() => {
    if (!loading && !error && allData) {
      const filteredWorks = Object.entries(allData).map(([dataId, data]) => ({
        ...data,
        id: dataId,
        d14: "8 minutes",
      }));

      console.log(filteredWorks, "filteredWorks");
      setMyData(filteredWorks);
    }

    /* eslint-disable-next-line */
  }, [allData, error]);

  const [showfilter, setshowfilter] = useState(false);
  const [showorder, setshoworder] = useState(false);

  const handleClick2 = () => {
    setshowfilter(showfilter ? false : true);
    setshoworder(false);
  };

  const handleClick1 = () => {
    setshoworder(showorder ? false : true);
    setshowfilter(false);
  };

  const [showOverlay1, setShowOverlay1] = useState(false);
  const location = useLocation();
  useEffect(() => {
   
    setShowOverlay1(false);
  }, [location.pathname]); 


  return (
    <div className="">
      <div className="sm:flex sm:items-center sm:flex-row-reverse gap-5 sm:mt-5 ">
        <BarNav
          value={active}
          setValue={setactive}
          t1={"Mulheres"}
          v1={"escort-womens"}
          t2={"Homens"}
          v2={"escort-mens"}
          t3={"Trans"}
          v3={"escort-trans"}
        />
        <div className="bg sm:w-1/2 btnbtn21 flex gap-2 items-center my-4 py-1.5 px-2 rounded-md ">
          <SearchIcon size={20} color="#8593A3" />
          <input
            type="text"
            placeholder="Busque pela sua cidade ou serviço que procura"
            className="outline-none tc bg section2-width pl-1 text-xs"
          />
        </div>
      </div>
      <div className="md:w-1/2 relative md:flex md:justify-between items-start">
        {who !== "post" && (
          <div className="md:w-1/2 mb-5 md:mb-0 mr-3">
            <div className="w-full  tc font-semibold text-xs btnbtn21 rounded-md text-center grid grid-cols-2">
              <div
                onClick={handleClick1}
                className="flex p-2 justify-center items-center hover custome-bord"
              >
                <SortIcon />
                <div>Ordenar</div>
              </div>
              <div
                onClick={handleClick2}
                className="flex p-2 justify-center items-center hover"
              >
                <Filter1Icon />
                <div>Filtrar</div>
              </div>
            </div>
          </div>
        )}
        <div className="md:w-1/2 md:mt-1  flex justify-start items-center ">
          <div onClick={() => setShowOverlay1(true)}>
            <LocationBtn text={getCityFromUrl()} />
          </div>
          <LocationBtn text={"Tem local"} />
        </div>

        <div className="absolute  top-5 w-full">
          <div className="z-40 relative bg box-shadow rounded-lg w-full">
            {showorder && <ShowOrder />}
          </div>
        </div>
      </div>
      {showOverlay1 && (
        <Overlay card={Location} setshowfilter={setShowOverlay1} />
      )}
      {showfilter && (
        <Overlay card={ShowFilter} setshowfilter={setshowfilter} />
      )}
    </div>
  );
};

export default Filter;

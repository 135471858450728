import React from "react";
import { LocationBtn } from "../../controller/components";

const Sec3 = () => {

  
  return (
    <div className="mb-3">
      <div className="text-sm font-normal my-2">Serviços</div>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-1">
        <LocationBtn text={"Categoria"} sm={true}/>
        <LocationBtn text={"Categoria"}  sm={true}/>
        <LocationBtn text={"Categoria"}  sm={true}/>
        <LocationBtn text={"Categoria"}  sm={true}/>
        <LocationBtn text={"Categoria"}  sm={true}/>
        <LocationBtn text={"Categoria"}  sm={true}/>
        <LocationBtn text={"Categoria"}  sm={true}/>
        <LocationBtn text={"Categoria"}  sm={true}/>
      </div>
    </div>
  );
};

export default Sec3;

import React, { useState } from "react";
import Sec1 from "./Sec1";
import Sec2 from "./Sec2";
import Sec3 from "./Sec3";
import To8s from "../cards/To8s";
import { sexOptions } from "../../data/Input";
import Sec4 from "./Sec4";
import { Filter1Icon } from "../../data/Data";

const ShowFilter = ({ setshowfilter }) => {
  const [dropdowns, setDropdowns] = useState(Array(18).fill(false));
  const toggleDropdown = (index) => {
    const updatedDropdowns = [...dropdowns];
    updatedDropdowns[index] = !updatedDropdowns[index];

    for (let i = 0; i < updatedDropdowns.length; i++) {
      if (i !== index) {
        updatedDropdowns[i] = false;
      }
    }

    setDropdowns(updatedDropdowns);
  };

  const handleSelect = (value, name) => {
    // handleChange(e);
    setDropdowns(Array(18).fill(false));
  };

  const myArray = [
    "Peso",
    "Altura",
    "Tamanho do pé",
    "Etnia",
    "Cabelo",
    "Tatuagens",
    "Piercings",
    "Silicone",
    "Cor dos olhos",
  ];
  const myArray1 = [
    "Fumante",
    "Comportamento sexual",
    "Atende",
    "Clientes em conjunto",
    "Idioma",
  ];

  return (
    <div className="w-full h-full overflow-auto">
      <div className="  p-5  text-sm py-3 ">
        <div className="flex justify-between border-bottom-brown items-center ">
          <div className="flex p-2 text-sm  hover">
            <Filter1Icon />
            <div>Filtrar</div>
          </div>
          <div className="hover" onClick={() => setshowfilter(false)}>Close</div>
        </div>
        <div className="border-bottom-brown py-5  grid grid-cols-2 gap-x-2">
          <div className="mr-5">
            <Sec1 title={"Valor"} t1={`R$ 0`} t2={`até + R$ 999`} />
            <Sec1 title={"Idade"} t1={`18 anos`} t2={`+ 60 anos`} />
            <Sec2 title={"Reviews"} desc={"Com review"} />
            <Sec2 title={"Expediente"} desc={"Em expediente agora"} />
            <Sec3 />
            <To8s
              bno={true}
              placehoder={"Método de pagamento"}
              //   value={escortData.lcountry}
              dropdowns={dropdowns}
              dropdownId={1}
              toggleDropdown={toggleDropdown}
              options={sexOptions}
              handleSelect={handleSelect}
              name={"pagamento"}
            />
          </div>
          <div>
            <Sec4
              shift={2}
              title={"Características físicas"}
              dropdowns={dropdowns}
              toggleDropdown={toggleDropdown}
              myArray={myArray}
            />
            <Sec4
              shift={12}
              title={"Outros"}
              dropdowns={dropdowns}
              toggleDropdown={toggleDropdown}
              myArray={myArray1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowFilter;

import React, { useState } from "react";
import { ArrowBackIcon, ArrowFrontIcon } from "../data/Data";

const Slider = ({
  who,
  theme,
  size,
  svg: Svg,
  className,
  data,
  length,
  isMobile,
  dots,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Handle going forward
  const handleNext = () => {
    if (currentIndex < data.length - length) {
      setCurrentIndex(currentIndex + length);
    }
  };

  // Handle going backward
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - length);
    }
  };

  // Calculate the total number of dots based on the total data length and the visible length
  const totalDots = Math.ceil(data.length / length);

  return (
    <div className="">
      <div className="flex justify-between items-center sm:-mx-8">
        <div onClick={handlePrev}>
          <IconSlide
            theme={theme}
            size={size}
            svg={ArrowBackIcon}
            className={""}
          />
        </div>

        <div className={`${className}`}>
          {Svg && (
            <Svg
              who={who}
              data={data.slice(currentIndex, currentIndex + length)}
              isMobile={isMobile ? isMobile : ""}
            />
          )}
        </div>

        <div onClick={handleNext}>
          <IconSlide
            theme={theme}
            size={size}
            svg={ArrowFrontIcon}
            className={""}
          />
        </div>
      </div>

      {/* Dots navigation */}
     {dots !== false && <div className="flex justify-center items-center my-5 sm:my-8 ">
        {[...Array(totalDots)].map((_, index) => (
          <div
            key={index}
            className={`w-1 h-1 rounded-full mr-1 ${theme ? 'bg' : 'themecom'} ${
              currentIndex / length === index ? "w-2 h-2" : ""
            }`}
          ></div>
        ))}
      </div>}
    </div>
  );
};

// Helper component for arrow icons
const IconSlide = ({ svg: Svg, size, theme, className }) => {
  return (
    <div
      className={`h-6 w-6 p-1.5 cursor-pointer ${className} ${
        theme ? "bg" : "themecom"
      } rounded-full flex justify-center items-center`}
    >
      <Svg
        size={size || 15}
        color={`${theme ? "var(--themeColor)" : "var(--bg)"}`}
      />
    </div>
  );
};

export default Slider;

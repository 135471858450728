import React from "react";
import To7 from "./To7";

const Board = ({ title, subtitle, text, img }) => {
  return (
    <div className="pt-10 sm:pt-16 z-10 px-8 pb-14 tcw background-container ">
      {text && <div className="sm:mb-5"><To7 text={text} /></div>}
      <div className={`text-center flex justify-center items-center mb-1 font-bold  ${img ? 'text-5xl' : 'text-3xl'}`}>
        <span>{img && <img src={img} alt="" className="w-8" />}</span>
        {title}</div>
      <div className="text-center text-sm my-2">{subtitle}</div>
    </div>
  );
};

export default Board;

import React from "react";
import To8 from "./To8";

const To23 = ({
  who,
  fullname,
  setFullname,
  efullname,
  username,
  setUsername,
  eusername,
  email,
  setRegisterEmail,
  eregisterEmail, 
  password,
  setRegisterPassword,
  eregisterPassword,
  confirmPassword,
  setConfirmPassword,
  econfirmPassword,
}) => {
  return (
    <div>
      <To8
        placehoder={"Nome completo*"}
        value={fullname}
        onChange={(e) => setFullname(e.target.value)}
        error={efullname}
        type={"text"}
      />
      <To8
        placehoder={"Nome de usuário*"}
        value={username}
        onChange={(e) => setUsername(e.target.value)} 
        error={eusername}
        type={"text"}
      />
      <To8
        placehoder={"Digite seu email*"}
        value={email}
        onChange={(e) => setRegisterEmail(e.target.value)}
        error={eregisterEmail}
        type={"email"}
      />
     <div className={`${ who !== 'client' ? "sm:grid sm:gap-5 sm:grid-cols-2" : ''}`}>
          <To8
            placehoder={"Crie uma senha*"}
            value={password}
            onChange={(e) => setRegisterPassword(e.target.value)}
            error={eregisterPassword}
            type={"password"}
          />
          <To8
            placehoder={"Digite a senha novamente*"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={econfirmPassword}
            type={"password"}
          />
     </div>
    </div>
  );
};

export default To23;

import React from "react";

import pic from "../../assets/p5.png";
import { Cancel1Icon } from "../../data/Data";
import { Love1Icon, PeepIcon, Share1Icon } from "../../data/Data";
import { Link } from "react-router-dom";
import { useGeneralBrain } from "../../controller/Brain";

const StatusCard = ({ data }) => {
  const { isMobile, setShowFullPage } = useGeneralBrain();
  const IconText = ({ svg: Svg, text, onClick }) => {
    return (
      <div
        onClick={onClick}
        className="flex items-center flex-col cursor-pointer hover"
      >
        <Svg size={isMobile ? 30 : 25} color={"#fff"} />
        <div className="text-sm font-medium">{text}</div>
      </div>
    );
  };
  return (
    <div className="w-full h-full  bg-black tcw">
      <div className="flex justify-between items-center p-5 w-full height-10">
        <div className="flex justify-between items-center tcw ">
          <img
            src={pic}
            alt=""
            className="w-14 h-14 rounded-full object-cover"
          />
          <div className="text-sm font-medium">Flávia Bianchini</div>
          <div className="text-xs font-normal">há 2 horas</div>
        </div>
        <div className="hover" onClick={() => setShowFullPage(false)}>
          <Cancel1Icon color="#fff" />
        </div>
      </div>

      <img src={pic} alt="" className="w-full height-80 object-cover bglb" />

      <div className="flex justify-between items-center p-5 w-full height-10">
        <div className="flex justify-between items-center">
          <IconText svg={Love1Icon} text={"Curtir"} />
        </div>
        <IconText svg={Share1Icon} text={"Compartilhar"} />
        <Link to={"ToProfile(data.u)"}>
          {" "}
          <IconText svg={PeepIcon} text={"Ver perfil"} />
        </Link>
      </div>
    </div>
  );
};

export default StatusCard;

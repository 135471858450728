import React from "react";

const ImgSvg = ({ img, alt, size, animate }) => {

let height;
let width;

if (size === 1) {
    height = "h-1"
    width = "w-1"
  } else if (size === 2) {
    height = "h-2"
    width = "w-2"
  } else if (size === 3) {
    height = "h-3"
    width = "w-3"
  } else if (size === 4) {
    height = "h-4"
    width = "w-4"
  } else if (size === 5) {
    height = "h-5"
    width = "w-5"
  }
  return <img src={img} alt={alt} className={`${width} ${height} `} />;
};

export default ImgSvg;

import React from "react";
import To8s from "../cards/To8s";
import { sexOptions } from "../../data/Input";

const Sec4 = ({title, dropdowns, toggleDropdown, myArray, shift }) => {
  

  const handleSelect = (value, name) => {
    // handleChange(e);
    
  };
  return (
    <div>

      <div className="tc my-2 text-xs font-normal opacity-30">{title}</div>
      <div className="ml-3">
        {myArray.map((item, index) => (
          <To8s
            key={index}
            bno={true}
            placehoder={item}
            //   value={escortData.lcountry}
            dropdowns={dropdowns}
            dropdownId={index + shift}
            toggleDropdown={toggleDropdown}
            options={sexOptions}
            handleSelect={handleSelect}
            name={"pagamento"}
          />
        ))}
      </div>
    </div>
  );
};

export default Sec4;

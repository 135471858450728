import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import dark from "../../assets/dark2.png";
import white from "../../assets/white2.png";

const LoadError = ({text}) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="allsvgclass my-5 md:my-20">
      <h3>Something went wrong!</h3>
      <div>
        {theme === "light" ? (
          <img src={dark} alt="Nothing yet" className="svgclass" />
        ) : (
          <img src={white} alt="Nothing yet" className="svgclass" />
        )}
      </div>
      <h5>{text || "Error"}</h5>
    </div>
  );
};

export default LoadError;

import React, { useState } from "react";
import To8s from "./To8s";
import To8 from "./To8";
import { Delete1Icon } from "../../data/Data";
import To27 from "./To27";

const To30 = ({ value, setValue, valors }) => {
  const [dropdowns, setDropdowns] = useState(Array(18).fill(false));

  const toggleDropdown = (index) => {
    const updatedDropdowns = [...dropdowns];
    updatedDropdowns[index] = !updatedDropdowns[index];

    // Close all other dropdowns except the one clicked
    for (let i = 0; i < updatedDropdowns.length; i++) {
      if (i !== index) {
        updatedDropdowns[i] = false;
      }
    }

    setDropdowns(updatedDropdowns);
  };

  // Add a new option to the array, each with its own id, time, and price
  const addOption = () => {
    setValue([...value, { time: "", price: "" }]); // Unique id with Date.now()
  };

  // Remove option at a specific index
  const removeOption = (index) => {
    const newOption = [...value];
    newOption.splice(index, 1);
    setValue(newOption);
  };

  // Update the value for the specific input fields
  const handleInputChange = (index, field, val) => {
    const newOptions = [...value];
    newOptions[index][field] = val;
    setDropdowns(Array(18).fill(false));
    setValue(newOptions);
  };



  return (
    <div>
      {value.map((v, index) => (
        <div className="flex items-center justify-between" key={v.index}> {/* Use id as the key */}
          <div className="w-11/12 md:grid md:grid-cols-2 md:gap-x-3 md:items-center">
            <To8s
              placehoder={"Tempo*"}
              value={v.time}
              dropdowns={dropdowns}
              dropdownId={index}
              toggleDropdown={toggleDropdown}
              options={valors}
              handleSelect={(selectedOption) =>
                handleInputChange(index, "time", selectedOption)
              } // Handle time selection
            />
            <To8
              placehoder={"Preço*"}
              value={v.price}
              setValue={(val) => handleInputChange(index, "price", val)}
              type={"number"}
            />
          </div>
          <div
            onClick={() => removeOption(index)}
            className="md:-mt-3 cursor-pointer"
          >
            <Delete1Icon color="var(--themeColor)" size={20} />
          </div>
        </div>
      ))}

     <div className="w-max"> <To27 text={"Adicionar valor"} onClick={addOption} /></div>
    </div>
  );
};

export default To30;

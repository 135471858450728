import React from "react";
import StatusCard from "../components/cards/StatusCard";
import To5b from "../components/cards/To5b";
import pic from "../assets/p5.png";
import { ChevLeftIcon, ChevRightIcon } from "../data/Data";
const Status = ({ id, data }) => {
  console.log(data, "to do status here");
  console.log(id, "id don land");

  return (
    <div className=" h-dvh tc bgbt overflow-hidden lg:flex lg:justify-center lg:items-center">
      <div className="lg:hidden w-full h-full">
        <StatusCard />
      </div>
      <div className="hidden  lg:-mx-24 lg:flex lg:justify-between lg:items-center">
        <To5b img={pic} name={"roma authur"} />
        <div className="flex items-center">
          <p className="mx-5 bg p-2 rounded-full hover">
            <ChevLeftIcon />
          </p>
          <div className="status-width-box">
            <StatusCard />
          </div>
          <p className="mx-5 bg p-2 rounded-full hover">
            <ChevRightIcon />
          </p>
        </div>
        <To5b img={pic} name={"roma authur"} />
      </div>
    </div>
  );
};

export default Status;

import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import DeletedUser from "./DeletedUser";

const NotMember = () => {
  const { userData } = useAuth();

  return (
    <>
      {userData?.status === "new" && (
        <div className="form-container">
          <div className="form box-shadow">
            <div className="confirm-container">
              <h2 className="confirm-title">One Last Step</h2>
              <div className="">
                <p className="confirm-text">
                  Your registration info has been submitted. Please wait for
                  admin approval.
                </p>
                <p className="confirm-text">
                  We will send you a mail to let you know when you get approved!
                </p>
                <p className="confirm-text">
                  If you don't see the email in your inbox, please check your
                  spam folder.
                </p>
              </div>
              <Link className="confirm-button" to={"/help"}>
                Help
              </Link>
            </div>
          </div>
        </div>
      )}

      {userData?.status === "deleted" && <DeletedUser />}
    </>
  );
};

export default NotMember;

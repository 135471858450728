import React from "react";
import { EditIcon } from "../../data/Data";
import Overlay from "../general/Overlay";

const To14 = ({
  text,
  tc,
  size,
  data,
  setData,
  showOverlay,
  setShowOverlay,
  card:Card,
}) => {
  const isEdit = window.location.pathname === "/editar-perfil";

  return (
    <div>
      <div className="flex justify-between items-center">
        <div
          className={`my-2 font-semibold ${
            size === "lg" ? "text-lg mb-2" : "text-sm"
          } ${tc ? tc : "btc"}`}
        >
          {text}
        </div>
        {isEdit &&  (
          <div onClick={() => setShowOverlay(true)} className="hover">
            <EditIcon />
          </div>
        )}
      </div>
      {showOverlay && (
        <Overlay
          card={Card}
          data={data}
          setData={setData}
          setshowfilter={setShowOverlay}
        />
      )}
    </div>
  );
};

export default To14;

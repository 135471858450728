import React from "react";
import Status from "../../controller/Status";
import { useGeneralBrain } from "../../controller/Brain";

const FullPage = () => {
  const { statusId, statusData, setShowFullPage } = useGeneralBrain();

  return (
    <div>
      {statusId !== "" && (
        <Status
          id={statusId}
          data={statusData}
          setShowFullPage={setShowFullPage}
        />
      )}
    </div>
  );
};

export default FullPage;

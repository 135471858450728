import { Link } from "react-router-dom";
import { CloseIcon } from "../data/Data";
import { useGeneralBrain } from "./Brain";

export const HomeSBtn = ({ text, url }) => {
  return (
    <Link to={url}>
      <div className="btnbtn3 bgl px-6 py-2 rounded-3xl text-center text-xs font-semibold capitalize m-2">
        {text}
      </div>
    </Link>
  );
};

export const HomeTBtn = ({ text, url }) => {
  return (
    <Link to={url}>
      <span className="btnbtn theme px-3 py-0.5 rounded-md text-center text-10 font-light capitalize">
        {text}
      </span>
    </Link>
  );
};

export const LocationBtn = ({ text, sm }) => {
  return (
    <div className="btnbtn flex items-center theme px-2 hover py-1 rounded-md">
      <CloseIcon size={sm ? 8 : 10} />
      <div className={` ${sm ? "text-8" : "text-10 "} font-medium w-4/5`}>
        {text}
      </div>
    </div>
  );
};

export const IconText = ({ svg: Svg, text, onClick }) => {
  const { isMobile } = useGeneralBrain();
  return (
    <div onClick={onClick} className="flex items-center cursor-pointer hover">
      <Svg size={isMobile ? 16 : 20} color={"var(--textColor)"} />
      <div className="text-sm font-medium">{text}</div>
    </div>
  );
};

export const Input = ({
  title,
  svg: Svg,
  placeholder,
  type,
  value,
  id,
  name,
  onChange,
}) => {
  return (
    <div className="flex-column">
      <label className="input-label">{title}</label>
      <div className="inputForm">
        <Svg />
        <input
          placeholder={placeholder}
          className="input theme"
          type={type}
          value={value}
          id={id}
          name={name}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export const ButtonIcon = ({ icon: Icon, text, onClick, loading, rounded }) => {
  return (
    <button
      className={`button-icon ${rounded ? "button-rounded" : ""}`}
      onClick={onClick}
    >
      {Icon && (
        <span className="button-icon">
          <Icon />
        </span>
      )}
      <span className="button-text">{loading ? "Wait..." : text}</span>
    </button>
  );
};

export const HomeButton = ({ text, onClick, loading }) => {
  return (
    <button className="button-submit" onClick={onClick}>
      {loading ? "Loading..." : text}
    </button>
  );
};

export const InputButton = ({
  ButtonText,
  title,
  placeholder,
  type,
  value,
  id,
  name,
  onChange,
  onClick,
}) => {
  return (
    <div className="button-input-container">
      <input
        placeholder={placeholder}
        className="input-btn btnbtn21"
        type={type}
        value={value}
        id={id}
        name={name}
        onChange={onChange}
      />
      <div className="button-input-class" onClick={onClick}>
        {ButtonText}
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import Board from "../../components/cards/Board";
import Filter from "../../controller/FilterPost";
import { useGeneralBrain } from "../../controller/Brain";
import PostCard from "../../components/cards/PostCard";
import { useFetchData } from "../../controller/Hooks";
import PageWait from "../../components/general/PageWait";
import LoadError from "../../components/general/LoadError";
import NothingYet from "../../components/general/NothingYet";

const AcompanEncontre = ({ query }) => { 
  const { isMobile } = useGeneralBrain();

  const [myData, setMyData] = useState([]);
  const [allData, setAllData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isActive, setIsActive] = useState("escort-womens");

  const {
    data: fetchedData,
    loading: fetchLoading,
    error,
    notFound,
  } = useFetchData({
    cols: "lives",
    docId: `${query}-${isActive}-post`,
  });

  useEffect(() => {
    setMyData([])
    setLoading(true);
    if (!fetchLoading) {
      setAllData(fetchedData);
      setLoading(fetchLoading);
    }
  }, [fetchedData, fetchLoading]);

  return (
    <div>
      <Board title={"Fotos e vídeos de acompanhantes"} my={true} />
      <div className="mx-5 my-5 sm:mx-20 md:mx-64 lg:mx-80">
        <Filter
          active={isActive}
          setactive={setIsActive}
          allData={allData}
          setMyData={setMyData}
          loading={loading}
        />
        {!isMobile && <div className="font-bold">Resultados</div>}
        {loading && <PageWait />}
        {error && !notFound && <LoadError />}
        {!loading && notFound && myData.length === 0 && <NothingYet />}
        {!loading && myData.length > 0 &&<div className="my-5">
          {myData.map((post => (
            <PostCard key={post.id} data={post} />
          )))}
        </div>}
      </div>
    </div>
  );
};

export default AcompanEncontre;

import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import dark from "../../assets/dark1.png";
import white from "../../assets/white1.png";

const Load404 = ({text}) => {

    const { theme } = useContext(ThemeContext);

  return (
    <div className="allsvgclass">
      <h3>404!</h3>
      <div>
        {theme === "light" ? (
          <img src={dark} alt="Nothing yet" className="svgclass" />
        ) : (
          <img src={white} alt="Nothing yet" className="svgclass" />
        )}
      </div>
      <h5>{text || "Page not found!"}</h5>
    </div>
  )
}

export default Load404
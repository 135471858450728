import React from "react";
import Load404 from "../components/general/Load404";

const NotFound = () => {
  return (
    <div className="my-5 md:my-20">
      <Load404 />
    </div>
  );
};

export default NotFound;

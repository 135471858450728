import React from "react";
import { HintIcon, QmarkIcon } from "../../data/Data";

const To8 = ({
  placehoder,
  infoText,
  type,
  value,
  setValue,
  onChange,
  error,
  name,
  id,
  label,
  ph,
}) => {
  const isonChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <>
      <div className="input-container">
        <div className="text-sm font-semibold btc mb-2">{label}</div>
        <div className={`${ ph ? "input-btn cursor-pointer hover:border-red-600 btnbtn21  bg rounded-md flex justify-between items-center" : ''}`}>
          {ph && <div className="w-2/12">{ph}</div>}
          <input
            type={type ? type : "text"}
            name={name ? name : "text"}
            id={id ? id : "text"}
            value={value ? value : placehoder}
            placeholder={placehoder ? placehoder : ''}
            onChange={onChange || isonChange}
            className={`bg ${ph ? " w-10/12 focus:border-none focus:outline-none" : 'input-btn btnbtn21 rounded-md'}`}
          />
        </div>
        
      </div>
      {error && (
        <div className="flex red -mt-2 items-center mb-4 text-xs ">
          <QmarkIcon color="var(--themeColor)" size={10} />
          <div>{error}</div>
        </div>
      )}
      {infoText && (
        <div className="flex -mt-2 items-center mb-4 btc text-xs ">
          <HintIcon />
          <div>{infoText}</div>
        </div>
      )}
    </>
  );
};

export default To8;

import React from "react";
import { Slider } from "@mui/material";

const Sec1 = ({ title, t1, t2 }) => {
  const marks = [
    {
      value: 0,
      label: '',
    },
    {
      value: 100,
      label: '',
    },
  ];

  function valuetext(value) { 
    return `${value}°C`;
  }
  return (
    <div className="mb-5">
      <div className="mt-2 text-sm font-normal">{title}</div>
      <div>
        <Slider
          track="inverted"
          aria-labelledby="track-inverted-range-slider"
          getAriaValueText={valuetext}
          defaultValue={[20, 37]}
          marks={marks}
          sx={{
            "& .MuiSlider-thumb": {
              color: "var(--themeColor)",
            },
            "& .MuiSlider-track": {
              color: "var(--themeColor)",
            },
            "& .MuiSlider-rail": {
              color: "#D9D9D9",
            },
            "& .MuiSlider-active": {
              color: "green",
            },
            
          }}
        />
       <div className="flex justify-between items-center text-xs -mt-3 font-medium">
        <div>{t1}</div>
        <div>{t2}</div>
       </div>
      </div>
    </div>
  );
};

export default Sec1;

import React from "react";
import { Link } from "react-router-dom";

const DeletedUser = () => {
  return (
    <div className="form-container">
      <div className="form box-shadow">
        <div className="confirm-container">
          <h2 className="confirm-title">Account Deleted</h2>
          <div className="">
            <p className="confirm-text">
              Your account has been deleted and all access to our community
              blocked!.
            </p>

            <p className="confirm-text">
              Still want to be part of our community? Contact support for help.
            </p>
          </div>
          <Link className="confirm-button" to={"/help"}>
            Help
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DeletedUser;

const Overlay = ({ card: Card, setshowfilter, data, setData, id }) => {
  return (
    <div id="overlay" className="">
      <div className="con-overlay"></div>
      <div className="con-box m-5 sm:mx-20 my-5 max-h-consent overflow-hidden bg rounded-2xl">
        {Card && (
          <Card
            id={id}
            setshowfilter={setshowfilter}
            data={data}
            setData={setData}
          />
        )}
      </div>
    </div>
  );
};

export default Overlay;

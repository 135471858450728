import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Brazil } from "../data/Brazil";
import { toLocal } from "./Functions";
import { SearchIcon } from "../data/Data";

const top10Cities = [
  { city: "São Paulo", state: "SP", region: "Sudeste" },
  { city: "Rio de Janeiro", state: "RJ", region: "Sudeste" },
  { city: "Belo Horizonte", state: "MG", region: "Sudeste" },
  { city: "Brasília", state: "DF", region: "Centro-Oeste" },
  { city: "Salvador", state: "BA", region: "Nordeste" },
  { city: "Fortaleza", state: "CE", region: "Nordeste" },
  { city: "Curitiba", state: "PR", region: "Sul" },
  { city: "Recife", state: "PE", region: "Nordeste" },
  { city: "Manaus", state: "AM", region: "Norte" },
  { city: "Porto Alegre", state: "RS", region: "Sul" }
];


// Binary search to find the index of the first city that starts with the query
const binarySearchStart = (cities, query) => {
  let left = 0;
  let right = cities.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    if (cities[mid].city.toLowerCase().startsWith(query)) {
      right = mid - 1; // Move left to find the first occurrence
    } else if (cities[mid].city.toLowerCase() < query) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }
  return left;
};

// Find cities starting with the query in the sorted list
const findCitiesByPrefix = (cities, query) => {
  const startIndex = binarySearchStart(cities, query);
  const result = [];

  for (let i = startIndex; i < cities.length; i++) {
    if (cities[i].city.toLowerCase().startsWith(query)) {
      result.push(cities[i]);
    } else {
      break; // No more matching cities
    }
  }

  return result;
};

const Location = ({ setshowfilter }) => {
  const [query, setQuery] = useState("");
  const [filteredCities, setFilteredCities] = useState(top10Cities);
  const [allCities, setAllCities] = useState([]);
  const location = useLocation();

  // Extract all cities into a single array on component mount
  useEffect(() => {
    const citiesList = [];

    // Loop through regions and states to extract cities
    Brazil.forEach((region) => {
      region.states.forEach((state) => {
        state.cities.forEach((city) => {
          citiesList.push({
            city: toLocal(city), // City name
            state: state.sign, // State abbreviation
            region: region.region, // Region name
          });
        });
      });
    });

    // Sort the cities alphabetically by their name
    const sortedCities = citiesList.sort((a, b) =>
      a.city.localeCompare(b.city)
    );
    // Set all cities into state for further use
    setAllCities(sortedCities);
  }, []);

  // Handle search query and filter the city list
  const handleSearch = (searchTerm) => {
    // Set loading when search begins

    if (searchTerm) {
      // Efficiently find cities that start with the search term
      const matchedCities = findCitiesByPrefix(allCities, searchTerm);
      setFilteredCities(matchedCities.slice(0, 20)); // Limit to top 8
    } else {
      setFilteredCities([]);
    }

    // Turn off loading after search
  }; // Adjust the debounce delay (300ms is good for responsiveness)

  // Handle input change and trigger debounce search
  const onInputChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setQuery(searchTerm);
    handleSearch(searchTerm);
  };

  // Determine the base URL based on the current path
  const baseUrl = location.pathname.includes("posts")
    ? "/posts"
    : "/acompanhantes";

  return (
    <div className="p-5 h-full w-full ">
      <div className="flex uppercase justify-between items-center my-3 ">
        <h1 className="text-xl font-bold">
          Selecionar <span className="theme">cidade</span>
        </h1>
        <div className="hover btnbtn rounded-md text-center text-xs font-normal py-1 px-3 bg theme" onClick={() => setshowfilter(false)}>
          Close
        </div>
      </div>

      <div
        onClick={() => setshowfilter(true)}
        className="bg flex gap-2  btnbtn21 items-center my-4 py-2 px-3 rounded-md"
      >
        <SearchIcon color="var(--textDim)" />
        <input
          type="text"
          value={query}
          onChange={onInputChange}
          placeholder="Busque pela sua cidade"
          className="outline-none tc bg section2-width text-sm w-11/12"
        />
      </div>

      <div className="overflow-auto">
        {filteredCities.length > 0 && (
          <div>
            {filteredCities.map(({ city, state, region }, index) => (
              <Link
                key={index}
                to={`${baseUrl}-${city
                  .toLowerCase()
                  .replace(/ /g, "-")}-${state.toLowerCase()}`}
                className="block p-2 border-b"
              >
                {city}, {state} ({region})
              </Link>
            ))}
          </div>
        )}

        {filteredCities.length === 0 && query && (
          <p>No matching cities found.</p>
        )}
      </div>
    </div>
  );
};

export default Location;

import React from "react";
import { Offline, Online } from "../../data/Status";

const OnlineCard = ({ time }) => {
  const lastOnline = `Online há 10 minutos`;
  const isOnline = true
  return (
    <div className="flex items-center text-inherit text-10">
      {isOnline && <Online />}
      {!isOnline && <Offline />}
      <span className="-ml-1"> {lastOnline}</span>
    </div>
  );
};

export default OnlineCard;

import React from "react";

const BarNav = ({ value, setValue, t1, t2, t3, t4, v1, v2, v3, v4 }) => {
  return (
    <div className="my-5 m-0 btnbtn h-8 rounded-md w-full sm:w-1/2 text-center grid grid-cols-3">
      {t1 && (
        <div
          onClick={() => setValue(v1)}
          className={`filter-btn ${value === v1 ? "filter-btn-active" : ""}`}
        >
          {t1}
        </div>
      )}
      {t2 && (
        <div
          onClick={() => setValue(v2)}
          className={`filter-btn m-0 custom-border-y ${
            value === v2 ? "filter-btn-active" : ""
          }`}
        >
          {t2}
        </div>
      )}
      {t3 && (
        <div
          onClick={() => setValue(v3)}
          className={`filter-btn ${value === v3 ? "filter-btn-active" : ""}`}
        >
          {t3}
        </div>
      )}
      {t4 && (
        <div
          onClick={() => setValue(v4)}
          className={`filter-btn ${value === v4 ? "filter-btn-active" : ""}`}
        >
          {t4}
        </div>
      )}
    </div>
  );
};

export default BarNav;
